import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '@foh/globals';


@Injectable({
  providedIn: 'root'
})
export class ConfigsService {

  constructor(private globals : Globals,private http : HttpClient) { }

    retrieveAll() {
        return this.http.get(this.globals.WEBAPI + this.globals.URL_CONFIGS_LIST);
    }

    updateSenderNameConfigs(params){
        return this.http.post(this.globals.WEBAPI + this.globals.URL_CONFIGS_SMSSENDERNAME_UPDATE,params);
    }
    updateFrequentConfigs(params){
        return this.http.post(this.globals.WEBAPI + this.globals.URL_CONFIGS_FREQUENT_UPDATE,params);
    }
    updateSpenderConfigs(params){
        return this.http.post(this.globals.WEBAPI + this.globals.URL_CONFIGS_SPENDER_UPDATE,params);
    }
    updateNoShowConfigs(params){
        return this.http.post(this.globals.WEBAPI + this.globals.URL_CONFIGS_NOSHOW_UPDATE,params);
    }
    updateDashboardConfigs(params){
        return this.http.post(this.globals.WEBAPI + this.globals.URL_CONFIGS_DASHBOARD_UPDATE,params);
    }
    updateGlobalConfigs(params){
        return this.http.post(this.globals.WEBAPI + this.globals.URL_CONFIGS_GLOBAL_UPDATE,params);
    }

    retrieveWeddingConfigs(){
        return this.http.get(this.globals.WEBAPI + this.globals.URL_CONFIGS_WEDDING_LIST);
    }
    updateWeddingConfigs(params){
        return this.http.post(this.globals.WEBAPI + this.globals.URL_CONFIGS_WEDDING_UPDATE,params);
    }
    updateWhatsAppSenderNameConfigs(data: any) {
        return this.http.post(this.globals.WEBAPI + this.globals.URL_CONFIGS_WHATSAPP_SENDER_NAME_UPDATE, data);
      }
}
